import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: "Poppins", "Open Sans",  sans-serif;
  }
  *{
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
        overflow-x: hidden; 

  }
`

export default GlobalStyle
