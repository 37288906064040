import styled from 'styled-components'

const Footer = styled.div`
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`

Footer.linkSection = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: center;
`

export default Footer
