import Cookies from 'universal-cookie'
export const isEuCustomer = () => {
  const cookies = new Cookies()
  const euCountries = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE',
    'FI', 'FR', 'DE', 'GB', 'GR', 'HU', 'IE', 'IS',
    'IT', 'LI', 'LV', 'LT', 'LU', 'MT', 'NL', 'NO',
    'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'EL'
  ]

  const cookieValue = (cookies.get('eigi-geolocated-country-code') || 'US').toUpperCase()
  return euCountries.includes(cookieValue)
}
export const getCurrencySymbol = () => {
  const cookies = new Cookies()
  const cookieValue = (cookies.get('eigi-geolocated-country-code') || 'US').toUpperCase()
  if (cookieValue === 'GB') {
    return {
      name: 'GBP',
      symbol: '£'
    }
  } else if (isEuCustomer()) {
    return {
      name: 'EUR',
      symbol: '€'
    }
  }
  return {
    name: 'USD',
    symbol: '$'
  }
}