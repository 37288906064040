import { useEffect, useState } from 'react'

const useSideNav = menuContainerRef => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const checkClick = e => {
      if (open) {
        if (!menuContainerRef.current.contains(e.target)) {
          setOpen(false)
        }
      }
    }
    if (window) {
      window.addEventListener('click', checkClick)
    }
    return () => {
      window.removeEventListener('click', checkClick)
    }
  }, [open, menuContainerRef])

  return [open, setOpen]
}

export default useSideNav
