import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ className, isInternal = true, to, children, ...rest }) => {
  return isInternal ? (
    <GatsbyLink className={className} to={to} {...rest}>
      {children}
    </GatsbyLink>
  ) : (
    <a className={className} href={to} {...rest}>
      {children}
    </a>
  )
}

export default Link
