import styled, { css } from 'styled-components'
import { theme, media } from './../../helpers/theme'
import person from './../../images/home-features-person.png'
import personMobile from './../../images/Home-features-person-mobile.png'
import EmbededVideo from '../video-embeded'


const HomeFeaturesSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;

  width: 100%;
`

const Wave = styled.img`
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  display: none;

  max-width: 50%;

  transition: all 0.5s;

  ${media('sm')} {
    margin-top: 25%;
  }

  ${media('md')} {
    display: block;
  }

  ${media('lg')} {
    align-self: center;
    margin-top: 0;
  }
`

const Content = styled.div`
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${theme.breakpoints['xl']}px;
  margin: 0 auto;

  ${media('lg')} {
    flex-direction: row;
    padding: 0 ${theme.space[8]}px;
  }
`

const Person = styled.img`
  min-width: 0;
  max-width: 100%;
  width: 100%;
  height: auto;

  content: url(${personMobile});

  ${media('md')} {
    content: url(${person});
    height: 520px;
    width: auto;
  }

  ${media('lg')} {
    width: 50%;
    height: auto;
  }
`

const AI = styled.div`
  width: 100%;
  padding: 0 ${theme.space[8]}px;
  max-width: ${theme.breakpoints.md}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 ${theme.space[4]}px;

  ${media('lg')} {
    padding: ${theme.space[8]}px ${theme.space[8]}px ${theme.space[3]}px;
  }
`

const Features = styled.div`
  min-width: 0;
  align-items: center;
  flex-basis: 50%;
  padding: 0 ${theme.space[8]}px;
  max-width: ${theme.breakpoints.sm}px;
  margin: 0 auto;

  margin-top: ${theme.space[8]}px;

  h3 {
    /* offset to line up with list items (offset bullets) */
    text-align: center;
    font-weight: bold;
    margin-left: ${theme.space[4]}px;
    font-size: ${theme.font['2xl']};
    ${media('md')} {
      font-size: ${theme.font['3xl']};
    }
    ${media('lg')} {
      font-size: ${theme.font['4xl']};
      text-align: left;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: ${theme.font['md']};
    ${media('md')} {
      font-size: ${theme.font['lg']};
    }
    ${media('xl')} {
      font-size: ${theme.font['xl']};
    }
  }

  ${media('lg')} {
    margin-top: 0;
    div {
      flex-direction: row;
      text-align: left;
      margin-bottom: ${theme.space[8]}px;
    }
  }
`

const ListBullet = styled.img`
  /* don't show the first bullet on mobile */
  ${({ first }) =>
    first
      ? css`
          display: none;
        `
      : ''}

  margin: ${theme.space[4]}px 0;
  height: 40px;

  ${media('lg')} {
    display: block;
    margin: 0 ${theme.space[8]}px;
    margin-left: ${theme.space[4]}px;
  }
`

const Title = styled.span`
  margin-bottom: ${theme.space[4]}px;
  font-weight: bold;
  font-size: ${theme.font['2xl']};
  ${media('md')} {
    font-size: ${theme.font['3xl']};
  }
  ${media('lg')} {
    font-size: ${theme.font['4xl']};
    margin-bottom: ${theme.space[8]}px;
  }
`

const Text = styled.span`
  margin-bottom: ${theme.space[4]}px;
  font-size: ${theme.font['md']};
  ${media('md')} {
    font-size: ${theme.font['lg']};
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }
`

const Video = styled(EmbededVideo)`
  width: 100%;
  padding: 0 ${theme.space[8]}px;
  max-width: ${theme.breakpoints.lg}px;
  height: 350px;
  margin: 0 auto;
  padding-bottom: ${theme.space[16]}px;
  ${media('md')} {
      height: 450px;
    }
    ${media('xl')} {
      height: 600px;
    }
`

HomeFeaturesSection.content = Content
HomeFeaturesSection.grid = Grid
HomeFeaturesSection.wave = Wave
HomeFeaturesSection.person = Person
HomeFeaturesSection.ai = AI
HomeFeaturesSection.features = Features
HomeFeaturesSection.title = Title
HomeFeaturesSection.text = Text
HomeFeaturesSection.listBullet = ListBullet
HomeFeaturesSection.Video = Video

export default HomeFeaturesSection
