import React from 'react'
import PropTypes from 'prop-types'
import Header from './../header'

import GlobalStyle from './GlobalStyles'

const Layout = ({
  children,
  isWhiteLogo,
  isPurple = false,
  hideLinks = false,
}) => (
  <>
    <GlobalStyle />
    <Header
      isWhiteLogo={isWhiteLogo}
      isPurple={isPurple}
      hideLinks={hideLinks}
    />
    <main>{children}</main>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isWhiteLogo: PropTypes.bool,
}

export default Layout
