import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import { getCurrencySymbol } from 'helpers/currency-helper'

import {
  fadeInAndSlide,
  breatheOut,
  slideUpDiagonal,
  slideUp,
  slideDown,
} from './animations'

// * Assets
import TopLeaf from '../assets/plant1.svg'
import BottomLeaf from '../assets/plant2.svg'
import OneDollarUSD from '../assets/oneDollar_usd.svg'
import OneDollarEUR from '../assets/oneDollar_eur.svg'
import OneDollarGBP from '../assets/oneDollar_gbp.svg'
// import BottomBlob from '../assets/bottomBackground.svg'

const Layout = styled.div`
  position: fixed;
  z-index: 5;

  bottom: ${theme.space[1]}px;
  right: ${theme.space[1]}px;

  ${media('md')} {
    bottom: ${theme.space[4]}px;
    right: ${theme.space[4]}px;
  }
`

Layout.Modal = styled.div`
  display: grid;
  background-color: #54277a; // one off color
  box-shadow: -4px 3px 10px 0px rgba(50, 50, 50, 0.2);
  border-radius: 3px;
  overflow: hidden;

  animation: ${fadeInAndSlide} 1s ease-in-out;

  grid-template-rows: 25px // X - button (row-1)
    50px // start of icon (row-2)
    35px // overlap row (row-3)
    65px // Title-row (row-4)
    70px // Detail-row (row-5)
    30px // button-row 1/3 (row-6)
    15px // button-row 2/3 (row-7)
    15px // button-row 3/3 (row-8)
    125px; // footer-row (row-9)
  grid-template-columns: 2px // Buffer (col-1)
    70px // Icon start (col-2)
    25px // Title Start (col-3)
    25px // Title/Button 1/5 (col-4)
    40px // Title/Button mid 2/5 (col-5)
    40px // Title/Button mid 3/5 (col-6)
    10px // Title/button End 4/5 (col-7)
    10px // Title/button End && Present Start 5/5 (col-8)
    88px; // present End (col-9)

  ${media('md')} {
    grid-template-rows: 25px // X - button (row-1)
      50px // start of icon (row-2)
      35px // overlap row (row-3)
      80px // Title-row (row-4)
      65px // Detail-row (row-5)
      30px // button-row 1/3 (row-6)
      15px // button-row 2/3 (row-7)
      15px // button-row 3/3 (row-8)
      130px; // footer-row (row-9)
    grid-template-columns: 0px // Buffer (col-1)
      80px // Icon start (col-2)
      40px // Title Start (col-3)
      25px // Title/Button 1/5 (col-4)
      50px // Title/Button mid 2/5 (col-5)
      50px // Title/Button mid 3/5 (col-6)
      15px // Title/button End 4/5 (col-7)
      10px // Title/button End && Present Start 5/5 (col-8)
      110px; // present End (col-9)
  }
`

// * TOP SECTION
Layout.TopLeaf = styled.img.attrs(() => ({ src: TopLeaf }))`
  grid-row: span 2;
  grid-column: 1 / 5;

  width: calc(100% + 20px);

  animation: ${breatheOut} 1s ease-in-out;
`

const getDollarImage = () => {
  switch(getCurrencySymbol().name) {
    case 'GBP':
      return OneDollarGBP
    case 'EUR':
      return OneDollarEUR
    default: 
      return OneDollarUSD
  }
}

Layout.Badge = styled.img.attrs(() => ({ src: getDollarImage() }))`
  grid-row: 1;
  grid-column: 2 / 5;

  width: 100%;

  animation: ${slideUpDiagonal} 1s ease-in-out;
`

Layout.Exit = styled.div`
  grid-row: 1;
  grid-column: 9;

  display: flex;
  justify-content: flex-end;
  padding: ${theme.space[2]}px;
  color: ${theme.colors.font.black};
`

Layout.ExitIcon = styled.span.attrs(() => ({
  'aria-label': 'Close',
  'aria-pressed': false,
  role: 'button',
}))`
  cursor: pointer;
  color: white;
`

// * TEXT AREA
Layout.MainTitle = styled.span`
  grid-row: 4;
  grid-column: 4 / 9;

  animation: ${slideUp} 1s ease-in-out;
  color: ${theme.colors.font.white};
  font-weight: 600;
  font-size: ${theme.font['3xl']};
  line-height: 0.9;

  ${media('md')} {
    font-size: ${theme.font['4xl']};
  }
`

Layout.SaleTitle = styled.span`
    grid-row: 4;
    grid-column: 4 / 9;
    align-self: flex-end;

    animation: ${slideUp} 1s ease-in-out;
    color: #F5BD2C; // one off color
    font-weight: 700;
    // font-size: ${theme.font['5xl']};
    font-size: 2.85rem;
    line-height: .95;

    ${media('md')} {
        // font-size: ${theme.font['6xl']};
        font-size: 3.5rem;
    }
`

Layout.SubtitleSection = styled.div`
  grid-row: 5;
  grid-column: 2 / 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.sm};

  ${media('md')} {
    font-size: ${theme.font.md};
  }

  font-weight: 400;
  padding: 0 ${theme.space[6]}px;
  text-align: center;
`

Layout.SubtitleRowOne = styled.div`
  color: rgba(255, 255, 255, 0.85);
`

Layout.SubtitleRowTwo = styled.div`
  color: rgba(255, 255, 255, 0.85);
`

Layout.SubtitleRowThree = styled.div``

// * BUTTON AND FOOTER

Layout.CTAButton = styled.a`
  grid-row: 6 / 9;
  grid-column: 4 / 8;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  white-space: nowrap;
  text-decoration: none;
  background: ${theme.colors.primary};
  color: ${theme.colors.font.white};
  border-radius: 5px;
  padding: ${theme.space[4]}px;

  font-size: ${theme.font['lg']};

  animation: ${slideDown} 1s ease-in-out;
`

Layout.BottomLeaf = styled.img.attrs(() => ({ src: BottomLeaf }))`
  grid-row: 5 / 10;
  grid-column: 9 / 10;

  justify-self: flex-end;
  width: calc(100% - 15px);
  align-self: center;

  z-index: 1;
`

// Layout.BottomBlob = styled.img.attrs(() => ({ src: BottomBlob }))`
//     grid-row: 9;
//     grid-column: 6 / 10;
//     align-self: end;

//     width: 100%;
//     height: auto;
//     z-index: 0;
// `

Layout.FooterNoteSection = styled.div`
  grid-row: 9;
  grid-column: 1 / 10;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: ${theme.space[2]}px ${theme.space[2]}px;
  z-index: 1;

  ${media('md')} {
    padding: ${theme.space[2]}px ${theme.space[10]}px;
  }
`

Layout.FooterNote = styled.span`
  color: rgba(255, 255, 255, 0.75);
  font-size: ${theme.font.xs};
`

export default Layout
