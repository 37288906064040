import styled from 'styled-components'

import { media, theme } from '../../helpers/theme'

import Link from '../../elements/link'

const LinkSection = styled.div`
  z-index: 5;
  display: flex;
  flex-wrap: wrap-reverse;

  ${media('sm')} {
    justify-content: space-between;
    flex-direction: row;
  }
`

LinkSection.section = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media('sm')} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

LinkSection.linkList = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: ${theme.space[4]}px;

  ${media('sm')} {
    padding: ${theme.space[8]}px;
  }
`

LinkSection.listTitle = styled(Link)`
  text-decoration: none !important;
  color: ${theme.colors.font.darkGray} !important;
  padding-bottom: ${theme.space[4]}px;

  ${media('md')} {
    font-size: 20px;
    line-height: 28px;
  }

  ${media('sm')} {
    min-height: 44px;
  }
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
`

LinkSection.label = styled.span`
  color: ${theme.colors.font.lightGray};
`

LinkSection.Link = styled(Link)`
  text-decoration: none !important;
  ${media('md')} {
    font-size: 18px;
    line-height: 32px;
  }
  font-size: 16px;
  line-height: 30px;
`

LinkSection.logo = styled.img`
  align-self: flex-end;
  box-sizing: content-box;
  width: 170px;
  padding: ${theme.space[8]}px;
`

LinkSection.LogoLink = styled(Link)``

export default LinkSection
