import styled from 'styled-components'
import Link from 'gatsby-link'
import theme, { media } from '../../helpers/theme'
import Lottie from "lottie-react"


const Header = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.isPurple ? theme.colors.primary : 'none'};
`

Header.headerContainer = styled.div`
  margin: 0 auto;
  max-width: ${theme.breakpoints['2xl']}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.space[2]}px ${theme.space[5]}px;
  justify-content: space-evenly;
 
  @media (min-width: 640px) {
  justify-content: space-between;
  }
`

Header.LogoContainer = styled.div`
  padding-left: ${theme.space[3]}px;
  margin-top: ${theme.space[3]}px;
`

Header.screenReaderText = styled.div.attrs(() => ({
  className: 'screen-reader-text',
  ariaLabel: 'Creativemail Assistance Info',
}))`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
`

Header.LogoLink = styled(Link)`
  
`

Header.animation = styled(Lottie)`
  width: 250px;
  height: 100px;
  margin: -${theme.space[6]}px 0 0 -${theme.space[8]}px;
`

Header.logo = styled.img`
  width: 250px;
  height: 100px;
  margin: -${theme.space[6]}px 0 0 -${theme.space[8]}px;
`

Header.Link = styled(Link)`
  margin-right: ${theme.space[4]}px;
  color: ${theme.colors.font.white};
  text-decoration: ${({ currentPath }) => (currentPath ? 'underline' : 'none')};
`

Header.Button = styled.a`
  font-family: Poppins, serif;
  margin: 0 ${theme.space[2]}px;
  background-color: ${(props) => props.bg};
  border: none;
  border-radius: 5px;
  padding: ${theme.space[2]}px ${theme.space[3]}px;
  color: ${(props) => props.color || theme.colors.font.white};
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
`
Header.IconContainer = styled.span`
  display: inline-flex;
  margin-left: -${theme.space[1]}px;
  margin-right: ${theme.space[1]}px;
  font-size: 18px;
`

Header.NavContainer = styled.span`
  display: none;
  align-items: center;
  ${media('sm')} {
    display: flex;
    margin-top: -${theme.space[5]}px;
  }
`
Header.MobileNav = styled.span`
  display: flex;
  align-items: center;
  padding: ${theme.space[2]}px;
  margin-top: -${theme.space[5]}px;
  ${media('sm')} {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
`

export default Header
