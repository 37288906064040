import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Header from './header.styles'
import SideNav from './sidenav.styles'
import theme from '../../helpers/theme'
import useSideNav from '../../helpers/hooks/useSideNav'

import HomeIcon from '@material-ui/icons/Home'
import MapIcon from '@material-ui/icons/Map'
import InboxIcon from '@material-ui/icons/Inbox'
import MenuIcon from '@material-ui/icons/Menu'
import ComputerIcon from '@material-ui/icons/Computer'
import whiteAnimationData from '../../images/logo_white_animated.json'
import primaryAnimationData from '../../images/logo_primary_animated.json'
import LogoGray from '../../images/logo_gray1.svg'
import Logo2 from '../../images/logo2.svg'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const HeaderComponent = ({
  isWhiteLogo,
  isPurple = false,
  hideLinks = false,
}) => {
  const path = typeof window !== 'undefined' ? window.location.pathname : ''
  const menuContainerRef = useRef(null)
  const [open, setOpen] = useSideNav(menuContainerRef)
  const lottieRef = useRef()

  useEffect(() => {
    if (path === '/' && lottieRef.current) {
      lottieRef.current.goToAndPlay(0)
    }
  }, [path, lottieRef])

  return (
    <>
      <Header isPurple={isPurple}>
        <Header.headerContainer>
          <Header.LogoContainer>
            <Header.LogoLink
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.logo'
              data-element-id='creativemail-header-logo'
              to='/'
            >

              <Header.logo
                src={Logo2}
                alt='Creativ.email Logo'
              />
              {/* <Header.animation
                onMouseEnter={() => lottieRef.current.goToAndPlay(0)}
                animationData={
                  isWhiteLogo ? whiteAnimationData : primaryAnimationData
                }
                lottieRef={lottieRef}
                loop={false}
                autoplay={false}
              /> */}
            </Header.LogoLink>
          </Header.LogoContainer>
          <Header.screenReaderText>
            If you are using assistive technology and are unable to read any
            part of the Creativemail.com website, or otherwise have difficulties
            using the Creativemail.com website, please call 844-217-8756 and our
            customer service team will assist you.
          </Header.screenReaderText>
          {!hideLinks && (
            <Header.NavContainer>
              <Header.Link
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.plans'
                data-element-id='creativemail-header-plans'
                to='/plans'
                currentPath={path.startsWith('/plans')}
              >
                Plans
              </Header.Link>
              <Header.Button
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.suggestions'
                data-element-id='creativemail-header-suggestions'
                bg={theme.colors.accent}
                color={theme.colors.font.black}
                href='mailto:feedback@creativemail.com'
              >
                <Header.IconContainer>
                  <InboxIcon fontSize='inherit' />
                </Header.IconContainer>
                <span>Suggestion Box</span>
              </Header.Button>
              <Header.Button
                data-element-location={DataElementLocations.HEADER}
                data-element-label='header.start-free'
                data-element-id='creativemail-header-start-free'
                bg={theme.colors.black}
                href={process.env.GATSBY_PLUGIN_URL}
              >
                <span>Start Free</span>
              </Header.Button>
            </Header.NavContainer>
          )}
          {!hideLinks && (
            <Header.MobileNav
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.menu-button'
              data-element-id='creativemail-header-menu-mobile'
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </Header.MobileNav>
          )}
        </Header.headerContainer>
      </Header>
      <SideNav ref={menuContainerRef} open={open}>
        <SideNav.Exit
          data-element-location={DataElementLocations.HEADER}
          data-element-label='header.exit-button'
          data-element-id='creativemail-header-exit-mobile'
          onClick={() => setOpen(false)}
        >
          &times;
        </SideNav.Exit>
        <SideNav.NavItem
          data-element-location={DataElementLocations.HEADER}
          data-element-label='header.home'
          data-element-id='creativemail-header-home-mobile'
          onClick={() => setOpen(false)}
          to='/'
        >
          <SideNav.IconContainer>
            <HomeIcon fontSize='inherit' />
          </SideNav.IconContainer>
          <span>Home</span>
        </SideNav.NavItem>
        {!hideLinks && (
          <>
            <SideNav.NavItem
              data-element-location={DataElementLocations.HEADER}
              data-element-label='header.plans'
              data-element-id='creativemail-header-plans-mobile'
              onClick={() => setOpen(false)}
              to='/plans'
            >
              <SideNav.IconContainer>
                <MapIcon fontSize='inherit' />
              </SideNav.IconContainer>
              <span>Plans</span>
            </SideNav.NavItem>
          </>
        )}
        <SideNav.NavItem
          data-element-location={DataElementLocations.HEADER}
          data-element-label='header.suggestions'
          data-element-id='creativemail-header-suggestions-mobile'
          isInternal={false}
          onClick={() => setOpen(false)}
          to='mailto:feedback@creativemail.com'
        >
          <SideNav.IconContainer>
            <InboxIcon fontSize='inherit' />
          </SideNav.IconContainer>
          <span>Suggestion Box</span>
        </SideNav.NavItem>
        <SideNav.Button
          data-element-location={DataElementLocations.HEADER}
          data-element-label='header.start-free'
          data-element-id='creativemail-header-start-free-mobile'
          isInternal={false}
          onClick={() => setOpen(false)}
          to={process.env.GATSBY_PLUGIN_URL}
        >
          START FREE
        </SideNav.Button>
        <SideNav.LogoContainer
          data-element-location={DataElementLocations.HEADER}
          data-element-label='header.logo'
          data-element-id='creativemail-header-logo-mobile'
          to='/'
          onClick={() => setOpen(false)}
        >
          <SideNav.Logo src={LogoGray} alt='Creativ.email Logo' />
        </SideNav.LogoContainer>
      </SideNav>
    </>
  )
}

HeaderComponent.propTypes = {
  isWhiteLogo: PropTypes.bool,
  isPurple: PropTypes.bool,
  hideLinks: PropTypes.bool,
}

export default HeaderComponent
