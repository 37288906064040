import React from 'react'

import LinkSection from '../link-section'

import Footer from './footer.styles'

const FooterSection = () => (
  <Footer>
    <Footer.linkSection>
      <LinkSection />
    </Footer.linkSection>
  </Footer>
)

export default FooterSection
