import styled from 'styled-components'
import Link from './../../elements/link'
import theme, { media } from '../../helpers/theme'

const SideNav = styled.div`
  position: fixed;
  width: 250px;
  height: 100%;
  top: 0;
  right: ${(props) => (props.open ? '0%' : '-100%')};
  transition: right 0.3s linear;
  z-index: 999;
  background-color: ${theme.colors.darkGray};
  padding-top: ${theme.space[16]}px;
  padding-left: ${theme.space[8]}px;
  display: unset;
  ${media('sm')} {
    display: none;
  }
`
SideNav.Exit = styled.span`
  position: absolute;
  color: ${theme.colors.font.offWhite};
  font-size: 32px;
  font-weight: bold;
  top: ${theme.space[3]}px;
  right: ${theme.space[5]}px;
  padding: 0 ${theme.space[3]}px;
  &:hover {
    cursor: pointer;
  }
`
SideNav.IconContainer = styled.span`
  font-size: 28px;
  margin-right: ${theme.space[4]}px;
  display: inline-flex;
`
SideNav.NavItem = styled(Link)`
  color: ${theme.colors.font.offWhite};
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${theme.space[3]}px;
  text-decoration: none;
`
SideNav.Button = styled(Link)`
  text-align: center;
  display: inline-block;
  width: calc(100% - ${theme.space[8]}px);
  padding: ${theme.space[2]}px 0;
  margin-top: ${theme.space[2]}px;
  font-weight: bold;
  border-radius: 3px;
  background-color: ${theme.colors.font.offWhite};
  color: ${theme.colors.font.darkGray};
  text-decoration: none;
`
SideNav.LogoContainer = styled(Link)`
  position: absolute;
  bottom: ${theme.space[8]}px;
  width: 100%;
  left: 0;
`
SideNav.Logo = styled.img`
  display: block;
  width: 180px;
  margin: 0 auto;
`

export default SideNav
