import React, { useState } from 'react'
import { getCurrencySymbol } from 'helpers/currency-helper'

import Layout from './veiws'

const SaleModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    // useEffect(() => {
    //     setTimeout(() => setIsModalOpen(true), 500)
    // }, [])

    return (
        isModalOpen ? (
            <Layout>
                <Layout.Modal>
                    <Layout.TopLeaf />
                    <Layout.Badge />
                    <Layout.Exit>
                        <Layout.ExitIcon onClick={()=> setIsModalOpen(false)}>
                            &#10005;
                        </Layout.ExitIcon>
                    </Layout.Exit>
                    {/* <Layout.TitleSection > */}
                        <Layout.MainTitle>
                            SPRING
                        </Layout.MainTitle>
                        <Layout.SaleTitle>
                            SALE
                        </Layout.SaleTitle>
                    {/* </Layout.TitleSection> */}
                    <Layout.SubtitleSection>
                        <Layout.SubtitleRowOne>
                            Get Awesome* email marketing
                        </Layout.SubtitleRowOne>
                        <Layout.SubtitleRowTwo>
                            for {getCurrencySymbol().symbol}1 per month!
                        </Layout.SubtitleRowTwo>
                    </Layout.SubtitleSection>
                    <Layout.CTAButton href={process.env.GATSBY_PLUGIN_URL}>
                        Get started
                    </Layout.CTAButton>
                    <Layout.BottomLeaf />
                    <Layout.FooterNoteSection>
                        <Layout.FooterNote>
                            *Good through 5/31/21. Discount applied at checkout. Flat monthly price for 3 months, regardless the # of contacts. Applies to Awesome plans only.
                        </Layout.FooterNote>
                    </Layout.FooterNoteSection>
                </Layout.Modal>
            </Layout>
        ) : null
    )
}

export default SaleModal
