import { keyframes } from 'styled-components'

export const fadeInAndSlide = keyframes`
    0% {
        opacity: 0;
        transform: translateX(10px);
    }

    100% {
        opacity 1;
        transform: translateX(0);
    }
`

export const breatheOut = keyframes`
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
`

export const slideUpDiagonal = keyframes`
    0% {
        transform: translate(15px, 15px);
    }

    100% {
        transform: translate(0px, 0px);
    }
`

export const slideUp = keyframes`
    0% {
        transform: translateY(35px);
    }

    100% {
        transform: translateY(0px);
    }
`

export const slideDown = keyframes`
    0% {
        transform: translateY(-35px);
    }

    100% {
        transform: translateY(0px);
    }
`
