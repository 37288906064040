import React from 'react'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Links from './link-section.styles'
import LogoPrimary from './../../images/logo_primary1.svg'
import { ALL_LINKS } from './link-section.constants'

const LinkSection = ({ links = ALL_LINKS }) => (
  <Links>
    <Links.section>
      {links.map(({ title, id, url, internal, isNewTab, sectionLinks }) => (
        <Links.linkList key={id}>
          <Links.listTitle to={url} target={isNewTab ? '_blank' : '_self'} isInternal={internal}>
            {title}
          </Links.listTitle>
          {sectionLinks.map(({ label, url, internal, isNewTab }) => (
            <Links.Link
              key={`${label}-${url}`}
              isInternal={internal}
              to={url}
              target={isNewTab ? '_blank' : '_self'}
              className={label === 'Cookie Settings' ? 'ot-sdk-show-settings':''}
              data-element-location={DataElementLocations.FOOTER}
              data-element-label={`cm-footer-links-${title}`}
              data-element-id={`cm-links-${title}-${label}`}
            >
              <Links.label>{label}</Links.label>
            </Links.Link>
          ))}
        </Links.linkList>
      ))}
    </Links.section>
    <Links.LogoLink
      data-element-location={DataElementLocations.FOOTER}
      data-element-label='footer.logo'
      data-element-id='creativemail-footer-logo'
      to='/'
    >
      <Links.logo src={LogoPrimary} />
    </Links.LogoLink>
  </Links>
)

export default LinkSection
