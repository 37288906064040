import React from 'react'
const videoUrl = 'https://www.youtube.com/embed/rI6j7JpZFSk?rel=0'

const Video = ({className}) => {
   return (
         <iframe
            src={videoUrl}
            title="video"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
            className={className}
         ></iframe>
   )
}

export default Video
