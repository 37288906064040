// * All Links will be considered internal by default by consumed component

const LEARN_MORE_LINKS = [
  {
    label: 'Plans',
    url: '/plans',
    internal: true,
    isNewTab: false,
  },
  {
    label: 'Help',
    url: process.env.GATSBY_SUPPORT_URL,
    internal: false,
    isNewTab: true,
  },
];

const COMPANY_LINKS = [
  {
    label: 'Privacy',
    url: 'https://newfold.com/privacy-center ',
    internal: false,
    isNewTab: true,
  },
  {
    label: 'Abuse',
    url: 'https://legal.newfold.com/',
    internal: false,
    isNewTab: true,
  },
  {
    label: 'Do Not Sell My Personal Information',
    url:
      'https://www.newfold.com/privacy-center/addendum-for-california-users ',
    internal: false,
    isNewTab: true,
  },
  {
    label: 'Cookie Settings',
    url: '#!',
    internal: false,
    isNewTab: false,
  },
];

const TERMS_LINKS = [
  {
    label: 'Terms of Service',
    url: 'https://legal.newfold.com',
    internal: false,
    isNewTab: true,
  },
];

const LEARN_MORE = {
  title: 'Learn More',
  id: 'Learn More',
  sectionLinks: LEARN_MORE_LINKS,
  url: '/',
  internal: true,
  isNewTab: false,
};

const COMPANY = {
  title: '',
  id: 'Company_No_Title',
  sectionLinks: COMPANY_LINKS,
  internal: false,
  isNewTab: false,
};

const TERMS = {
  title: 'Company',
  id: 'Company_With_Title',
  sectionLinks: TERMS_LINKS,
  url: 'https://www.newfold.com/',
  isNewTab: true,
};

export const ALL_LINKS = [LEARN_MORE, TERMS, COMPANY];
